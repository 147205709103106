import React from "react"


import Layout from "../components/layout"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{width:`100%`,height:`0`, paddingBottom:`100%`, position:`relative`}}><iframe title= "bumps" src="https://giphy.com/embed/l378gQ3QqIrlnhfbi" width="100%" height="100%" style={{position:`absolute`}} frameBorder="0" className="giphy-embed" allowFullScreen></iframe></div>
  </Layout>
)

export default IndexPage
